import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Link } from 'gatsby';
import { Heading } from '@primer/react';
import FormsLayout from '~/src/layouts/forms-layout';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LinkExternalIcon = makeShortcode("LinkExternalIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = FormsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Creating accessible forms can be challenging. Forms are a common source of accessibility issues, and can hinder someone from successfully completing and submitting a form. This can be true even for people who do not use assistive technologies.`}</p>
    <p>{`The Primer forms framework is designed to be accessible by default, integrate deeply with Rails, follow web standards, and adhere to Primer's `}<a parentName="p" {...{
        "href": "/ui-patterns/forms/overview"
      }}>{`form interface guidelines`}</a>{`.`}</p>
    <h2>{`Example`}</h2>
    <p>{`Forms are defined as Ruby classes...`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`class SignInForm < ApplicationForm
  form do |sign_in_form|
    sign_in_form.text_field(name: :username, label: "Username")
    sign_in_form.text_field(name: :password, label: "Password")
    sign_in_form.submit(label: "Sign in")
  end
end
`}</code></pre>
    <p>{`...and rendered like view components.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-erb"
      }}>{`<%= primer_form_with(url: "/sign-in") do |f| %>
  <%= render(SignInForm.new(f)) %>
<% end %>
`}</code></pre>
    <h2>{`Documentation`}</h2>
    <p>{`The forms framework is capable of much more. For full documentation, see the `}<Link href="https://primer.style/view-components/lookbook/pages/forms/introduction" mdxType="Link"><LinkExternalIcon mdxType="LinkExternalIcon" />{` Primer forms docs`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      